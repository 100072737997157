<!--
 * @Author: your name
 * @Date: 2022-04-01 11:39:01
 * @LastEditTime: 2022-04-16 14:55:35
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\components\layout\index.vue
-->
<template>
  <div>
    <tabbar/>  
  </div>
</template>
<script>
import tabbar from "@/components/tabbar/index.vue"
export default {
  data() {
    return{
    }
  },
  components:{
    tabbar
  }
}
</script>
<style lang="less" scoped>
</style>